import styled from "@emotion/styled/macro";
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Services from "../components/Services";

const FAQContainer = styled.div`
  padding-top: clamp(40px, 5vw, 100px);
  padding-bottom: clamp(40px, 5vw, 100px);
  padding-left: clamp(40px, 5vw, 100px);
  padding-right: clamp(40px, 5vw, 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 900px) {
    align-items: start;
  }
`;

const FAQTitle = styled.h3`
  color: black;
  font-family: Lora;
  font-size: calc(1rem + 3vw);
  font-weight: 200;
  padding-bottom: 2.5vw;
  max-width: 1200px;
  @media (max-width: 900px) {
    padding-bottom: 10px;
  }
`;

const QuestionsContainer = styled.div`
  display: flex;
  max-width: 1200px;
  column-gap: 50px;
  @media (max-width: 900px) {
    display: block;
  }
`;

const Column = styled.div`
  flex: 1;
`;

const Question = styled.p`
  color: #26965c;
  font-weight: 500;
  font-size: clamp(16px, calc(0.8rem + 2vw), 27px);
`;

const Answer = styled.p`
  font-weight: 500;
  font-size: clamp(16px, calc(0.8rem + 2vw), 27px);
  line-height: 1.5;
`;

const ItalicizedText = styled.span`
  font-style: italic;
`;

const ServicesPage: React.FC = () => {
  return (
    <>
      <Header />
      <Services />
      <FAQContainer>
        <FAQTitle>Frequently <ItalicizedText>Asked</ItalicizedText> Questions</FAQTitle>
        <QuestionsContainer>
          <Column>
            <Question>Are you accepting New Patients?</Question>
            <Answer>
              Yes! We are happy to accept new patients. Our New Patient appointments are about 45 minutes long and cover mobility tests as well as an orthopedic test.
            </Answer>
          </Column>
          <Column>
            <Question>Do you take insurance?</Question>
            <Answer>
              We take Medicare Part-B but are an Out-Of-Network provider in the
              Lebanon, Oregon area. We’re also happy to provide you with diagnostic
              statements that you’re able to submit for reimbursement.
            </Answer>
          </Column>
        </QuestionsContainer>
      </FAQContainer>
      <Footer />
    </>
  );
};

export default ServicesPage;
