import styled from "@emotion/styled/macro";

const ServiceContainer = styled.div`
  background-color: #f2efeb;
  text-align: center;
  text-align: -webkit-center;
  max-width: none;
  padding-top: clamp(50px, 3vw, 100px);
  padding-bottom: clamp(70px, 5vw, 100px);
  padding-left: clamp(0px, 5vw, 100px);
  padding-right: clamp(0px, 5vw, 100px);
`;

const ServiceTitle = styled.h3`
  font-family: Lora;
  font-size: calc(1rem + 3vw);
  font-weight: 200;
  color: black;
  padding-bottom: 2.5vw;
`;

const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  max-width: 1300px;
  row-gap: 40px;
  column-gap: 20px;
`;

const GridItem = styled.div`
  text-align: center;
`;

const GridImage = styled.img`
  object-fit: contain;
  border-radius: 35px;
  aspect-ratio: 3 / 2;
  max-width: 300px;
  max-height: 200px;
  width: calc(15vw + 2.5rem);
  @media (max-width: 900px) {
    width: 100%;
  }
  @media (max-width: 705px) {
    width: 100%;
    max-width: 60vw;
    max-height: none;
  }
`;

const GridText = styled.p`
  font-family: Lora;
  font-size: 1.1rem;
  font-style: italic;
  color: black;
  padding-top: 15px;
`;

const imagesData = [
  {
    image: `${process.env.PUBLIC_URL}/images/wbh_services_chiropractic_care.jpg`,
    text: "Chiropractic Care",
  },
  {
    image: `${process.env.PUBLIC_URL}/images/wbh_services_pediatric_chiropractic.jpg`,
    text: "Pediatric Chiropractic",
  },
  {
    image: `${process.env.PUBLIC_URL}/images/wbh_services_performance_rehab.jpg`,
    text: "Performance Rehab",
  },
  {
    image: `${process.env.PUBLIC_URL}/images/wbh_services_post_concussion_therapy.jpg`,
    text: "Post Concussion Therapy",
  },
  {
    image: `${process.env.PUBLIC_URL}/images/wbh_services_x_ray_imaging.jpg`,
    text: "X-Ray Imaging",
  },
  {
    image: `${process.env.PUBLIC_URL}/images/wbh_services_physiotherapy.jpg`,
    text: "Physiotherapy",
  },
  {
    image: `${process.env.PUBLIC_URL}/images/wbh_services_manual_therapy.jpg`,
    text: "Manual Therapy",
  },
];

const Services: React.FC = () => {
  return (
    <ServiceContainer>
      <ServiceTitle>OUR SERVICES</ServiceTitle>
      <GridContainer>
        {imagesData.map((item, index) => (
          <GridItem key={index}>
            <GridImage src={item.image} alt={`Service ${index + 1}`} />
            <GridText>{item.text}</GridText>
          </GridItem>
        ))}
      </GridContainer>
    </ServiceContainer>
  );
};

export default Services;
