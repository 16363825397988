import styled from "@emotion/styled/macro";
import { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { NavLink as RouterNavLink, NavLinkProps } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";

const StyledNavbar = styled(Navbar)`
  font-family: "Helvetica Neue", Arial, sans-serif;
  text-align: center;
  background: white;
  padding: 0px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.img`
  max-height: 65px;
  height: 17vw;
  width: auto;
`;

const SocialIcon = styled.img`
  height: 15px;
  width: 15px;
  @media (max-width: 991px) {
    height: 20px;
    width: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const StyledNavbarBrand = styled(Navbar.Brand)`
  @media (max-width: 400px) {
    font-size: 23px;
  }
`;

const StyledNavbarOffcanvas = styled(Navbar.Offcanvas)`
  visibility: hidden !important;
  @media (max-width: 991px) {
    min-width: -webkit-fill-available;
    visibility: ${(props) =>
      props.visible ? "visible !important" : "hidden !important"};
  }
`;

const StyledNav = styled(Nav)`
  @media (max-width: 991px) {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
  }
`;

const NavLinksContainer = styled.div`
  display: flex;
  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    row-gap: 20px;
  }
`;

const SocialLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledNavLink = styled(RouterNavLink)`
  align-self: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-decoration: none;
  padding: 5px 10px;
`;

const StyledActiveNavLink = styled(
  ({
    activeClassName,
    ...rest
  }: NavLinkProps & { activeClassName: string }) => <RouterNavLink {...rest} />
)`
  font-family: "Helvetica Neue", Arial, sans-serif;
  color: black;
  font-size: 15px;
  font-weight: 700;
  align-self: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0;
  letter-spacing: 1px;
  @media (max-width: 991px) {
    font-size: calc(1.5rem + 0.25vw);
  }
  &.active {
    &:after {
      content: "";
      position: absolute;
      width: calc(100% - 15px);
      height: 1px;
      background-color: black;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      @media (max-width: 991px) {
        width: 100%;
      }
    }
  }
`;

const ContactButton = styled(Button)`
  font-family: Helvetica;
  background-color: #26965c;
  border-radius: 300px;
  border: none;
  padding: 0.9em 1.8em;
  font-size: 0.95rem;
  @media (max-width: 991px) {
    min-width: 20vw;
  }
  &:hover {
    background-color: #4fac7d;
  }
`;

export default function Header() {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 991) {
        setShowOffcanvas(false);
      }
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggleOffcanvas = () => setShowOffcanvas(!showOffcanvas);

  return (
    <StyledNavbar expand="lg" variant="light">
      <Container fluid>
        <StyledNavbarBrand href="/home">
          <Logo
            src={`${process.env.PUBLIC_URL}/images/wbh_logo_with_text.png`}
            alt="Whole Body Health Logo"
          />
        </StyledNavbarBrand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={handleToggleOffcanvas}
        />
        <StyledNavbarOffcanvas
          visible={showOffcanvas}
          show={showOffcanvas}
          onHide={() => setShowOffcanvas(false)}
          id={`offcanvasNavbar-expand`}
          aria-labelledby={`offcanvasNavbarLabel-expand`}
          placement="start"
        >
          <Offcanvas.Header closeButton>
            <StyledNavbarBrand href="/home">
              <Logo
                src={`${process.env.PUBLIC_URL}/images/wbh_logo_with_text.png`}
                alt="Whole Body Health Logo"
              />
            </StyledNavbarBrand>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <StyledNav className="ms-auto">
              <NavLinksContainer>
                <StyledActiveNavLink
                  to="/home"
                  className="nav-link"
                  activeClassName="active"
                >
                  HOME
                </StyledActiveNavLink>
                <StyledActiveNavLink
                  to="/about"
                  className="nav-link"
                  activeClassName="active"
                >
                  MEET THE TEAM
                </StyledActiveNavLink>
                <StyledActiveNavLink
                  to="/services"
                  className="nav-link"
                  activeClassName="active"
                >
                  SERVICES
                </StyledActiveNavLink>
              </NavLinksContainer>
              <SocialLinksContainer>
                <StyledNavLink
                  to={
                    "https://www.facebook.com/people/Whole-Body-Health-Lebanon/100089215748528/"
                  }
                >
                  <SocialIcon
                    src={`${process.env.PUBLIC_URL}/images/facebook_logo.svg`}
                    alt="Facebook"
                  ></SocialIcon>
                </StyledNavLink>
                <StyledNavLink to={"https://www.linkedin.com/company/whole-body-health-llc/"}>
                  <SocialIcon
                    src={`${process.env.PUBLIC_URL}/images/linkedin_logo.svg`}
                    alt="LinkedIn"
                  ></SocialIcon>
                </StyledNavLink>
              </SocialLinksContainer>
              <StyledNavLink to="/contact">
                <ContactButton>CONTACT</ContactButton>
              </StyledNavLink>
            </StyledNav>
          </Offcanvas.Body>
        </StyledNavbarOffcanvas>
      </Container>
    </StyledNavbar>
  );
}
