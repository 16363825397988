import React from "react";
import styled from "@emotion/styled/macro";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Services from "../components/Services";

const backgroundImageUrl = `${process.env.PUBLIC_URL}/images/anatomy_photos.jpg`;

const Background = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(${backgroundImageUrl});
  background-size: cover;
  overflow: hidden;
`;

const TitleContainer = styled.div`
  color: white;
  text-align: center;
  max-width: none;
  padding-top: 100px;
  padding-bottom: 100px;
`;

const WelcomeText = styled.h1`
  font-family: Helvetica, Arial, sans-serif;
  font-size: calc(0.8rem + 0.6vw);
  margin: 0;
  padding-bottom: 20px;
`;

const Title = styled.h1`
  font-family: Lora;
  font-size: calc(1.1rem + 6.7vw);
`;

const Subtitle = styled.h2`
  font-family: Lora;
  font-size: calc(0.8rem + 1.85vw);
  margin: 0;
`;

const LocationText = styled.p`
  font-family: Helvetica;
  font-size: calc(0.8rem + 0.25vw);
  margin: 0.5rem 0;
`;

const AppointmentButton = styled.a`
  background-color: #26965c;
  border-radius: 300px;
  border: none;
  padding: 1em 1.8em;
  margin-top: 25px;
  margin-bottom: 5px;
  color: white;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  &:hover {
    background-color: #4fac7d;
  }
`;

const PatientStatusText = styled.p`
  text-align: center;
  font-family: arial;
  font-weight: 700;
  font-size: calc(0.8rem + 0.2vw);
  margin: 0.5rem 0;
`;

const PromiseContainer = styled.div`
  text-align: center;
  text-align: -webkit-center;
  max-width: none;
  padding-top: clamp(70px, 5vw, 100px);
  padding-bottom: clamp(70px, 5vw, 100px);
  padding-left: clamp(40px, 5vw, 100px);
  padding-right: clamp(40px, 5vw, 100px);
`;

const PromiseTitle = styled.h3`
  color: #26965c;
  font-family: arial;
  font-size: calc(0.85rem + 0.3vw);
  font-weight: 600;
`;

const PromiseParagraph = styled.p`
  font-family: Lora;
  font-weight: 500;
  font-size: clamp(16px, calc(0.8rem + 2vw), 35px);
  line-height: 1.5;
  max-width: 700px;
  color: black;
`;

const HighlightedText = styled.span`
  background-color: #f2efeb;
  border-radius: 15px;
  padding: 0 5px;
`;

const ItalicizedText = styled.span`
  font-style: italic;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const QuestionContainer = styled.div`
  text-align: left;
  max-width: none;
  padding-top: clamp(70px, 5vw, 100px);
  padding-bottom: clamp(70px, 5vw, 100px);
  padding-left: clamp(40px, 5vw, 100px);
  padding-right: clamp(40px, 5vw, 100px);
  position: relative;
`;

const QuestionTitle = styled.h3`
  color: black;
  font-family: Lora;
  font-size: calc(1rem + 3vw);
  font-weight: 200;
  width: 50%;
  padding-bottom: 50px;
  @media (max-width: 705px) {
    width: 100%;
    padding-bottom: 30px;
  }
`;

const QuestionParagraph = styled.p`
  color: black;
  font-weight: 500;
  font-size: calc(1rem + 0.4vw);
  line-height: 1.5;
  max-width: 60%;
  @media (max-width: 705px) {
    max-width: none;
  }
`;

const BandsImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 25%;
  max-width: 300px;
  height: auto;
  border-bottom-left-radius: 50px;
  @media (max-width: 705px) {
    display: none;
  }
`;

const SkeletonImage = styled.img`
  position: absolute;
  top: 10%;
  right: 5%;
  width: 25%;
  max-width: 300px;
  height: auto;
  border-top-left-radius: 250px;
  border-top-right-radius: 150px;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  @media (max-width: 705px) {
    display: none;
  }
`;

const ExperienceContainer = styled.div`
  padding-top: clamp(70px, 5vw, 100px);
  padding-bottom: clamp(70px, 5vw, 100px);
  padding-left: clamp(40px, 5vw, 100px);
  padding-right: clamp(40px, 5vw, 100px);
  background-color: #f2efeb;
`;

const ExperienceTitle = styled.h3`
  color: black;
  font-family: Lora;
  font-size: calc(1rem + 3vw);
  font-weight: 200;
  padding-bottom: 20px;
`;

const ExperienceList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding-left: 40px;
  @media (max-width: 705px) {
    padding-left: 20px;
    flex-direction: column;
  }
`;

const ExperienceListItem = styled.li`
  color: black;
  font-size: calc(1rem + 0.4vw);
  letter-spacing: 0.03em;
  flex-basis: 50%;
  position: relative;
  margin-bottom: 10px;
  padding-right: 35px;
  &:before {
    content: "•";
    position: absolute;
    left: -17px;
    font-size: 20px;
  }
`;

const experienceData = [
  { text: "Post Concussion therapy" },
  { text: "Pediatric Chiropractic Care" },
  { text: "Car accident injuries" },
  { text: "Osteoarthritis" },
  { text: "Sports injuries" },
  { text: "Sciatica" },
  { text: "Whiplash" },
  { text: "Nerve damage" },
  { text: "Age-related musculoskeletal issues" },
  { text: "Tension from stress or anxiety" },
  { text: "Workplace issues, such as the strain of sitting at a desk" },
  { text: "Neck, Shoulder, Back, and Muscle pain" },
  { text: "Headaches" },
  { text: "and more…" },
];

const FounderContainer = styled.div`
  background-color: #26965c;
  color: white;
  text-align: left;
  max-width: none;
  padding-top: clamp(70px, 5vw, 100px);
  padding-bottom: clamp(70px, 5vw, 100px);
  padding-left: clamp(40px, 5vw, 100px);
  padding-right: clamp(40px, 5vw, 100px);
  position: relative;
`;

const FounderTitle = styled.h3`
  font-family: arial;
  font-size: calc(0.85rem + 0.2vw);
`;

const FounderName = styled.h4`
  font-family: Lora;
  font-size: calc(1rem + 3vw);
  font-weight: 200;
  width: 55%;
  padding-top: 20px;
  padding-bottom: 20px;
  @media (max-width: 900px) {
    width: 80%;
  }
`;

const FounderRole = styled.h5`
  font-family: Helvetica;
  font-weight: 400;
  @media (max-width: 900px) {
    padding-bottom: 20px;
  }
`;

const LineBreak = styled.hr`
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
  margin-bottom: 35px;
  background-color: white;
  opacity: 100%;
  position: relative;
  z-index: 1;
  @media (max-width: 900px) {
    max-width: 80%;
  }
`;

const FounderBio = styled.p`
  font-family: Lora;
  font-weight: 500;
  font-size: clamp(16px, calc(0.8rem + 2vw), 27px);
  line-height: 1.5;
  max-width: 65%;
  @media (max-width: 900px) {
    max-width: 100%;
  }
`;

const FounderEducation = styled.p`
  font-weight: 500;
  font-size: calc(1rem + 0.4vw);
  line-height: 1.5;
  max-width: 60%;
  @media (max-width: 900px) {
    max-width: 100%;
  }
`;

const FounderImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 35%;
  height: auto;
  object-fit: cover;
  z-index: 2;
  @media (max-width: 900px) {
    position: relative;
    width: 100%;
    clip-path: polygon(0% 15%, 100% 15%, 100% 75%, 0% 75%);
    transform: translateY(-15%);
    margin-bottom: -58%;
  }
`;

const MeetButton = styled.a`
  background-color: white;
  color: #26965c;
  border-radius: 300px;
  border: none;
  padding: 1em 1.8em;
  margin-top: 25px;
  margin-bottom: 5px;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #26965c;
    background-color: #d4eade;
  }
`;

const HomePage: React.FC = () => {
  return (
    <>
      <Background>
        <Header />
        <TitleContainer>
          <WelcomeText>WELCOME TO</WelcomeText>
          <Title>Whole Body Health</Title>
          <Subtitle>Your Local Chiropractic & Rehab Center</Subtitle>
          <LocationText>Located in Lebanon Oregon</LocationText>
          <AppointmentButton href="/contact">
            BOOK AN APPOINTMENT
          </AppointmentButton>
          <PatientStatusText>
            Yes, We Are Accepting NEW Patients.
          </PatientStatusText>
        </TitleContainer>
      </Background>
      <PromiseContainer>
        <PromiseTitle>OUR PROMISE</PromiseTitle>
        <PromiseParagraph>
          Every day,{" "}
          <HighlightedText>we prioritize our patients' needs</HighlightedText>{" "}
          by <ItalicizedText>offering holistic care</ItalicizedText> with
          integrity, superior service, and{" "}
          <ItalicizedText>unwavering commitment</ItalicizedText> to our promise
          of <ItalicizedText>quality healthcare</ItalicizedText>.
        </PromiseParagraph>
      </PromiseContainer>
      <Services />
      <QuestionContainer>
        <QuestionTitle>
          How to know if <ItalicizedText>chiropractic care</ItalicizedText> is
          right for you?
        </QuestionTitle>
        <QuestionParagraph>
          At Whole Body Health, we're here to help your
          body heal itself from any{" "}
          <ItalicizedText>
            unpredictable accidents or long-term injuries
          </ItalicizedText>{" "}
          caused by everyday wear and tear such as stiffness, on-going pain and
          other annoying heath bothers. Our approach is all about taking care of
          you from head to toe with our wide range of supportive services,{" "}
          making sure you're feeling your absolute best!
        </QuestionParagraph>
        <BandsImage
          src={process.env.PUBLIC_URL + "/images/bands.jpg"}
          alt="Bands"
        />
        <SkeletonImage
          src={process.env.PUBLIC_URL + "/images/skeleton.jpg"}
          alt="Skeleton"
        />
      </QuestionContainer>
      <ExperienceContainer>
        <ExperienceTitle>
          Areas Dr. Savannah and her team have expertise in:
        </ExperienceTitle>
        <ExperienceList>
          {experienceData.map((item, index) => (
            <ExperienceListItem key={index}>{item.text}</ExperienceListItem>
          ))}
        </ExperienceList>
      </ExperienceContainer>
      <FounderContainer>
        <FounderTitle>MEET OUR FOUNDER</FounderTitle>
        <FounderName>Dr. Savannah Gleaves, DC, CMES, BSc, BA</FounderName>
        <FounderRole>
          Chiropractic Physician & Medical Exercise Specialist
        </FounderRole>
        <FounderImage
          src={process.env.PUBLIC_URL + "/images/wbh_staff_savannah.jpg"}
          alt="Dr. Savannah Gleaves"
        />
        <LineBreak />
        <FounderBio>
          Dr. Gleaves is a patient-focused and community-oriented Chiropractic
          Physician who is well-connected and passionate about utilizing her
          higher education and medical expertise to better the lives of every
          current and future patient. She is motivated to make a lasting impact,
          and does so through a high-volume, high-quality approach in her clinic
           Whole Body Health by providing care through personalized and
          individual treatment plans. As a medical professional and business
          leader, she leverages these strengths to generate growth in Lebanon,
          OR while imparting knowledge about living a healthy, balanced
          lifestyle.
        </FounderBio>
        <FounderBio>
          Dr. Gleaves is a Chiropractic Physician, Medical Exercise Specialist, 
          and Owner and Founder of Whole Body Health LLC with 10+ years of
          experience and exposure in Chiropractic Care, Business Management, and
           Administration. She is skilled in building and maintaining businesses
          that have a mission centralized around positively impacting the lives
          of her patients and community. The driving force behind this patient
          focused approach stems from an early age, growing up with her two
          brothers with various levels of developmental disabilities. These
          personal experiences fueled her passion and commitment to improving
          the lives of others through proper healthcare.
        </FounderBio>
        <FounderEducation>
          Dr. Gleaves obtained her Doctorate of Chiropractic and her Bachelor of
          Science in Human Biology from the University of Western States. She
          also holds degrees from the University of Oregon and Linn-Benton
          Community College. In addition, she has received various
          certifications such as Medical Exercise Specialist and completed
          continued education courses in post-concussion care, adjusting styles,
          and x-ray diagnostics.
        </FounderEducation>
        <MeetButton href="/about">MEET THE WBH TEAM</MeetButton>
      </FounderContainer>
      <Footer />
    </>
  );
};

export default HomePage;
