import React from "react";
import styled from "@emotion/styled/macro";
import Header from "../components/Header";
import Footer from "../components/Footer";

const backgroundImageUrl = `${process.env.PUBLIC_URL}/images/wbh_staff_team.png`;

const Background = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(${backgroundImageUrl});
  background-size: cover;
  background-position: bottom;
  height: 100%;
  padding-top: 25vw;
  padding-bottom: 14vw;
  position: relative;
`;

const TitleContainer = styled.div`
  color: white;
  text-align: center;
  position: absolute;
  bottom: 20%;
  left: 0;
  right: 0;
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: 405px) {
    bottom: 3%;
  }
`;

const MeetText = styled.p`
  font-family: arial;
  font-size: calc(1.1rem + 0.2vw);
  font-weight: 600;
  margin-bottom: -8px;
  margin-right: 16.1vw;
`;

const TeamText = styled.h1`
  font-family: Lora;
  font-size: calc(1.6rem + 2.25vw);
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const ItalicizedText = styled.span`
  font-style: italic;
`;

const FounderBackground = styled.div`
  background-color: #26965c;
  color: white;
  padding-top: clamp(40px, 5vw, 100px);
  padding-bottom: clamp(40px, 5vw, 100px);
  padding-left: clamp(40px, 5vw, 100px);
  padding-right: clamp(40px, 5vw, 100px);
  position: relative;
  display: flex;
  justify-content: center;
`;

const FounderContentContainer = styled.div`
  max-width: 1200px;
  display: flex;
  @media (max-width: 800px) {
    display: block;
  }
`;

const FounderTitle = styled.h3`
  font-family: Helvetica;
  font-weight: 700;
  font-size: calc(0.95rem);
  letter-spacing: 0.03em;
  opacity: 98%;
`;

const FounderName = styled.h4`
  font-family: Lora;
  font-size: calc(1.3rem + 2.5vw);
  font-weight: 200;
  width: fit-content;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
`;

const FounderRole = styled.h5`
  font-family: Helvetica;
  font-weight: 400;
  padding-bottom: 15px;
  @media (max-width: 800px) {
    padding-bottom: 20px;
  }
`;

const LineBreak = styled.hr`
  width: 80%;
  opacity: 100%;
  padding-bottom: 10px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const FounderBioMain = styled.p`
  font-family: Lora;
  font-weight: 500;
  font-size: clamp(16px, calc(0.8rem + 2vw), 27px);
  line-height: 1.5;
  width: fit-content;
  padding-right: 20px;
`;

const FounderBioExtra = styled.p`
  font-size: clamp(18px, calc(0.7rem + 0.6vw), 27px);
  opacity: 98%;
  line-height: 1.5;
  width: fit-content;
  padding-right: 20px;
`;

const FounderImageLarge = styled.img`
  width: 35vw;
  height: 35vw;
  max-width: 400px;
  max-height: 400px;
  border-radius: 100%;
  object-fit: cover;
  margin-bottom: 50px;
  @media (max-width: 800px) {
    display: none;
  }
`;

const FounderImageSmallContainer = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const FounderImageSmall = styled.img`
  width: 80vw;
  height: 80vw;
  border-radius: 20%;
  object-fit: cover;
  margin-bottom: 30px;
`;

const FounderQualificationsTitle = styled.h5`
  font-weight: 500;
  font-size: calc(1rem + 0.4vw);
  line-height: 1.5;
`;

const FounderQualificationsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  list-style-type: none;
`;

const FounderQualificationsListItem = styled.li`
  font-size: 1rem;
  position: relative;
  margin-bottom: 10px;
  &:before {
    content: "•";
    position: absolute;
    left: -17px;
    top: -4px;
    font-size: 20px;
  }
`;

const AssistantAbililitesContainer = styled.div`
  background-color: black;
  color: white;
  text-align: center;
  text-align: -webkit-center;
  padding-top: clamp(70px, 5vw, 100px);
  padding-bottom: clamp(70px, 5vw, 100px);
  padding-left: clamp(40px, 5vw, 100px);
  padding-right: clamp(40px, 5vw, 100px);
`;

const AssistantAbililites = styled.p`
  font-family: Lora;
  font-weight: 500;
  font-size: clamp(16px, calc(0.8rem + 2vw), 40px);
  line-height: normal;
  max-width: 1100px;
`;

const AllAssistantsContainer = styled.div`
  background-color: #f2efeb;
  padding-top: clamp(70px, 5vw, 100px);
  padding-bottom: clamp(30px, 2vw, 100px);
  padding-left: clamp(40px, 5vw, 100px);
  padding-right: clamp(40px, 5vw, 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AssistantContainer = styled.div`
  display: flex;
  column-gap: 40px;
  max-width: 1200px;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;

const AssistantImageContainer = styled.div`
  width: 50vw;
  height: 50vw;
  max-width: 350px;
  max-height: 350px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  @media (max-width: 800px) {
    max-width: 100%;
    max-height: 100%;
  }
`;

const AssistantImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
  transform: scale(1.05);
`;

const AssistantName = styled.h4`
  font-family: Lora;
  font-size: calc(1.3rem + 2.5vw);
  font-weight: 700;
  color: #26965c;
  margin-bottom: 0;
  padding-top: 20px;
`;

const AssistantRole = styled.p`
  font-family: Lora;
  font-size: clamp(17px, calc(0.7rem + 0.8vw), 27px);
`;

const AssistantBio = styled.p`
  font-size: clamp(15px, calc(0.7rem + 0.6vw), 27px);
`;

const AssistantQualificationsTitle = styled.p`
  font-family: arial;
  font-size: 1rem;
  font-weight: 600;
`;

const AssistantQualificationsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  list-style-type: none;
  padding-left: 30px;
`;

const AssistantQualificationsListItem = styled.li`
  font-size: 1rem;
  position: relative;
  margin-bottom: 10px;
  &:before {
    content: "•";
    position: absolute;
    left: -17px;
    top: -4px;
    font-size: 20px;
  }
`;

const AssistantLineBreak = styled.hr`
  width: 100%;
  opacity: 100%;
  margin-top: 20px;
  margin-bottom: 50px;
`;

const assistantsData = [
  {
    name: "Zack",
    imageSrc: process.env.PUBLIC_URL + "/images/wbh_staff_zack.jpg",
    role: "Licensed Chiropractic Assistant (License No. 9932)",
    bioBold: `Hailing from Lebanon, Oregon, Zack grew up playing basketball, soccer, and competing in track & field. He continued his athletic career as a thrower at the University of Idaho. After serving as a Special Education Teacher at Lebanon High School, Zack joined our team.`,
    bio: `Outside of work, Zack's passion for athletics continues. He trains and competes locally as a post-collegiate shot putter for Cascadia Elite. During summers, he proudly represents his birth country, Honduras, at international competitions, traveling to countries like Mexico, Peru, Costa Rica, Nicaragua, The Bahamas, and El Salvador. When away from work and training, Zack enjoys spending quality time with his wife, Kylie, and their two Golden Retrievers, Nash and Lundyn.`,
    education: [
      "Bachelor of Science in Exercise, Sport, and Health Sciences from the University of Idaho 2020",
    ],
    otherCertificates: [
      "BLS CPR Certified",
      "First Aid and CPR with AED Certified",
    ],
  },
  {
    name: "Jordan",
    imageSrc: process.env.PUBLIC_URL + "/images/wbh_staff_jordan.jpg",
    role: "Licensed Chiropractic Assistant (License No. 10043)",
    bioBold: `Growing up in the Columbia River Gorge, Jordan graduated from Oregon State University with a degree in Kinesiology.`,
    bio: `Fueled by her experience as a competitive gymnast, Jordan is pursuing a career as a Chiropractic Physician specializing in Sports Medicine. She has been accepted to Parker University where she will receive her Doctor of Chiropractic and Masters of Sports Medicine degrees. Her passion for helping athletes extends beyond the clinic, as she previously coached both recreational and competitive gymnastics for four years. When away from work and studies, Jordan enjoys unwinding in a hammock or exploring the water with friends on her paddleboard.`,
    education: [
      "Honors Bachelor of Science in Kinesiology with an option in Pre-Physical Therapy from Oregon State University",
    ],
    otherCertificates: ["BLS CPR Certified"],
  },
  {
    name: "Zoe",
    imageSrc: process.env.PUBLIC_URL + "/images/wbh_staff_zoe.jpg",
    role: "Licensed Chiropractic Assistant (License No. 10195)",
    bioBold: `Zoe was born and raised in Keizer where she participated in sports such as swimming, cross country running, and cheerleading.`,
    bio: `She joined the team after working in a variety of roles at American University in Washington, D.C., Salem Hospital Emergency Department, and Lebanon High School. Zoe is a registered EMT and enjoys spending time with her husband Jacob, her dog Hazel, and their chickens. She can be found playing her piano, enjoying the outdoors, and trying out local restaurants.`,
    otherCertificates: [
      "BLS CPR Certified",
      "Licensed EMT (License No. 204331)",
    ],
  },
  {
    name: "Alisha",
    imageSrc: process.env.PUBLIC_URL + "/images/wbh_staff_alisha.jpg",
    role: "Front Office Manager",
    bioBold: `Being an Oregon native, Alisha grew up homeschooled, participating in large animal 4-H in the Benton County district and volunteering at the Philomath Frolic and Rodeo.`,
    bio: `At the age of 16 she pursued her GED and went on to attend LBCC where she graduated 3 years later with an Associates Degree in Graphic Arts, specializing in Website Design and Personal Brand Development. In 2011 she became the mother of twin boys and spent the next 13 years as a stay-at-home mom while building her own business as an online branding specialist. Desiring a change, she joined the team here at Whole Body Health where she is able to continue pursuing her passion for community and helping others. When not at the Clinic, she can be found exploring the outdoors with her family, engaging in community activities with her church at Valley Life in Lebanon, or floating rivers on a paddle board with her dog George.`,
    education: [
      "Associate of Applied Science from Linn-Benton Community College 2005",
    ],
    otherCertificates: ["Certificate of Graphic Arts"],
  },
  {
    name: "Sarah",
    imageSrc: process.env.PUBLIC_URL + "/images/wbh_staff_sarah.jpg",
    role: "Front Office Manager",
    bioBold: `Sarah is an Oregonian at heart having moved here for college and staying to build a life in the beautiful Pacific Northwest.`,
    bio: `She attended Willamette University in 2012, and earned her Masters in Social Work in 2021. Sarah joined the team after spending several years working with children as a program coordinator, a children's therapist, and a college counselor. She spent her childhood in Southern California enjoying surfing, camping, and crafting with her friends and family. While out of the office, she spends her days running a small business and exploring Oregon with her husband Kyle, and corgi Zoey. Some of their favorite hobbies are paddleboarding in the river, strolling on the beach, and playing board games with friends.`,
    education: [
      "Bachelor of Arts in Psychology from Willamette University 2016",
      "Masters of Social Work from Pacific University 2021",
    ],
  },
  {
    name: "Shelby",
    imageSrc: process.env.PUBLIC_URL + "/images/wbh_staff_shelby.jpg",
    role: "Billing Specialist",
    bioBold: `Shelby grew up in Stayton Oregon, and has been in the medical field since the start of her career, with experience working as a Medical Assistant, a Surgical Technician and more recently, working in Orthopedics.`,
    bio: `Having taken a break for the past 3 years as a stay-at-home mom for her two kiddos, Shelby joined the Whole Body Health team with an interest in Chiropractic Care and is ready to get back into the medical field she has a personal passion for. When not at the clinic, she enjoys being outdoors with her family and often spends weekends working events with her Mobile Beverage Trailer (a hobby business) that she started with her husband.`,
    education: ["NCMA (Nationally Certified Medical Assistant) 2019"],
  },
];

const AboutPage: React.FC = () => {
  return (
    <>
      <Header />
      <Background>
        <TitleContainer>
          <MeetText>MEET THE</MeetText>
          <TeamText>
            <BoldText>Whole Body Health</BoldText>{" "}
            <ItalicizedText>team</ItalicizedText>
          </TeamText>
        </TitleContainer>
      </Background>
      <FounderBackground>
        <FounderContentContainer>
          <div>
            <FounderTitle>MEET OUR FOUNDER</FounderTitle>
            <FounderName>Dr. Savannah Gleaves, DC, CMES, BSc, BA</FounderName>
            <FounderRole>
              Chiropractic Physician & Medical Exercise Specialist
            </FounderRole>
            <FounderImageSmallContainer>
              <FounderImageSmall
                src={process.env.PUBLIC_URL + "/images/wbh_staff_savannah.jpg"}
                alt="Dr. Savannah Gleaves"
              />
            </FounderImageSmallContainer>
            <LineBreak />
            <FounderBioMain>
              <BoldText>
                Fondly known as Dr. Sav, and originally from the Willamette
                Valley, her journey towards becoming a chiropractor was sparked
                by her desire towards helping individuals like her older
                brother, Russell, who had suffered from cerebral palsy and
                epilepsy all his life. Witnessing the positive impact of
                chiropractic care on individuals with similar conditions further
                fueled her passion.
              </BoldText>
            </FounderBioMain>
            <FounderBioExtra>
              Her focus on community service and mission driven work extends
              much further than just the clinic. Dr. Gleaves is committed to
              giving back globally and fulfilling a vision that thriving
              communities deserve access to affordable sustainable healthcare
              and patient education. She lives and breathes the idea that people
              deserve to be served “across the street and around the world” to
              achieve an optimal self-reliant lifestyle. Volunteer positions as
              a Physician in Kimana, Kenya, and President and Co-Founder of a
              501 (c)(3) Foundation supporting families of patients who are
              diagnosed with brain altering diseases are examples of how she is
              living out this vision one day at a time.
            </FounderBioExtra>
          </div>
          <div>
            <FounderImageLarge
              src={process.env.PUBLIC_URL + "/images/wbh_staff_savannah.jpg"}
              alt="Dr. Savannah Gleaves"
            />
            <FounderQualificationsTitle>Education</FounderQualificationsTitle>
            <FounderQualificationsList>
              <FounderQualificationsListItem>
                Associate of Arts from Linn-Benton Community College 2013
              </FounderQualificationsListItem>
              <FounderQualificationsListItem>
                Bachelor of Arts in Economics, Business & Society from
                University of Oregon 2015
              </FounderQualificationsListItem>
              <FounderQualificationsListItem>
                Bachelor of Science in Human Biology from University of Western
                States 2020
              </FounderQualificationsListItem>
              <FounderQualificationsListItem>
                Doctorate of Chiropractic from University of Western States 2021
              </FounderQualificationsListItem>
            </FounderQualificationsList>
            <FounderQualificationsTitle>
              Other Studies and Certifications
            </FounderQualificationsTitle>
            <FounderQualificationsList>
              <FounderQualificationsListItem>
                Activator Methods Technique
              </FounderQualificationsListItem>
              <FounderQualificationsListItem>
                BLS CPR Certified
              </FounderQualificationsListItem>
              <FounderQualificationsListItem>
                Certified Medical Exercise Specialist
              </FounderQualificationsListItem>
              <FounderQualificationsListItem>
                First Aid & CPR with AED Certified
              </FounderQualificationsListItem>
              <FounderQualificationsListItem>
                Former Assistant Swim Coach
              </FounderQualificationsListItem>
            </FounderQualificationsList>
          </div>
        </FounderContentContainer>
      </FounderBackground>
      <AssistantAbililitesContainer>
        <AssistantAbililites>
          <BoldText>As a Licensed Chiropractic Assistant,</BoldText> each team
          member can answer questions regarding the doctor’s treatment plan,
          recommendations for care, and help to teach you any prescribed rehab
          or therapies.
        </AssistantAbililites>
      </AssistantAbililitesContainer>
      <AllAssistantsContainer>
        {assistantsData.map((assistant, index) => (
          <React.Fragment key={index}>
            <AssistantContainer key={index}>
              <AssistantImageContainer>
                <AssistantImage src={assistant.imageSrc} alt={assistant.name} />
              </AssistantImageContainer>
              <div>
                <AssistantName>{assistant.name}</AssistantName>
                <AssistantRole>{assistant.role}</AssistantRole>
                <AssistantBio>
                  <BoldText>{assistant.bioBold}</BoldText> {assistant.bio}
                </AssistantBio>
                {assistant.education && (
                  <>
                    <AssistantQualificationsTitle>
                      EDUCATION
                    </AssistantQualificationsTitle>
                    <AssistantQualificationsList>
                      {assistant.education.map((qualification, index) => (
                        <AssistantQualificationsListItem key={index}>
                          {qualification}
                        </AssistantQualificationsListItem>
                      ))}
                    </AssistantQualificationsList>
                  </>
                )}
                {assistant.otherCertificates && (
                  <>
                    <AssistantQualificationsTitle>
                      OTHER STUDIES + CERTIFICATES
                    </AssistantQualificationsTitle>
                    <AssistantQualificationsList>
                      {assistant.otherCertificates.map((certificate, index) => (
                        <AssistantQualificationsListItem key={index}>
                          {certificate}
                        </AssistantQualificationsListItem>
                      ))}
                    </AssistantQualificationsList>
                  </>
                )}
              </div>
            </AssistantContainer>
            {index !== assistantsData.length - 1 && <AssistantLineBreak />}
          </React.Fragment>
        ))}
      </AllAssistantsContainer>
      <Footer />
    </>
  );
};

export default AboutPage;
