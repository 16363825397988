import styled from "@emotion/styled/macro";
import React, { useState, FormEvent, ChangeEvent } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID as string;
const SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID as string;
const PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY as string;

const ContactContainer = styled.div`
  background-color: #f2efeb;
  text-align: center;
  text-align: -webkit-center;
  max-width: none;
  padding-top: clamp(50px, 3vw, 100px);
  padding-bottom: clamp(70px, 5vw, 100px);
  padding-left: clamp(20px, 5vw, 100px);
  padding-right: clamp(20px, 5vw, 100px);
`;

const ContactTitle = styled.h3`
  font-family: Lora;
  font-size: clamp(16px, calc(0.8rem + 2vw), 50px);
  font-weight: 200;
  color: black;
  padding-bottom: 2.5vw;
`;

const FormContainer = styled.div`
  max-width: 1000px;
  margin: 40px;
  background: #26965c;
  color: white;
  border-top-left-radius: 40px;
  border-top-right-radius: 45px;
  border-bottom-right-radius: 45px;
  border-bottom-left-radius: 40px;
  display: flex;
  column-gap: 20px;
  @media (max-width: 900px) {
    flex-direction: column-reverse;
    border-radius: 40px;
    margin-top: 30px;
    margin-bottom: 0;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const InfoContainer = styled.div`
  padding: 30px;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 900px) {
    padding-top: 20px;
  }
`;

const InfoTitle = styled.h4`
  font-family: "Lora";
  font-size: 2rem;
  font-weight: 100;
  margin-bottom: 25px;
  @media (max-width: 900px) {
    display: none;
  }
`;

const InfoSubtitle = styled.h5`
  font-family: "Lora";
  font-weight: 700;
  position: relative;
  width: min-content;
  margin-bottom: 10px;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: white;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const LineBreak = styled.hr`
  width: 90%;
  background-color: white;
  opacity: 100%;
  position: relative;
  z-index: 1;
`;

const InfoContent = styled.p`
  font-size: calc(0.8rem + 0.25vw);
  margin-bottom: 2px;
  overflow-wrap: break-word;
`;

const StyledFormTitle = styled(Form)`
  font-family: "Lora";
  font-size: clamp(16px, calc(0.8rem + 2vw), 50px);
  font-weight: 100;
  color: white;
  background-color: #26965c;
  padding: 10px;
  margin-bottom: 20px;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  display: none;
  @media (max-width: 900px) {
    display: block;
  }
`;

const StyledForm = styled(Form)`
  background: white;
  color: black;
  padding: 30px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  width: 100%;
  @media (max-width: 900px) {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
    border-top-left-radius: 40px;
    border-bottom-right-radius: 0;
  }
`;

const StyledFormGroup = styled(Form.Group)`
  text-align: left;
  @media (max-width: 900px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const StyledFormLabel = styled(Form.Label)`
  margin-left: 5px;
`;

const SendButton = styled(Button)`
  background-color: #26965c;
  border-radius: 300px;
  border: none;
  padding: 1em 1.8em;
  margin-top: 25px;
  margin-bottom: 5px;
  &:hover {
    background-color: #4fac7d;
  }
`;

const FeedbackMessage = styled.p`
  color: white;
`;

const StyledToast = styled(ToastContainer)`
  &&&.Toastify__toast-container {
  }
  .Toastify__toast {
    background-color: white;
    color: black;
    border: solid black 2px;
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;

const ContactPage: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [reason, setReason] = useState<string>("Chiropractic Care");
  const [validated, setValidated] = useState<boolean>(false);

  // Toast notifications
  const notifySuccess = () =>
    toast.success("Message sent successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const notifyFailure = () =>
    toast.error("Error sending message!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  // Function to validate email
  function validateEmail(text: string): boolean {
    const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(text);
  }

  // Handle phone input format
  function handlePhoneInput(e: ChangeEvent<HTMLInputElement>) {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhone(formattedPhoneNumber);
  }

  // Format phone number
  function formatPhoneNumber(value: string): string {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  // Handle form submission
  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setValidated(true);

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    if (!validateEmail(email)) {
      event.stopPropagation();
      return;
    }

    // Send email using emailjs
    emailjs
      .send(
        SERVICE_ID,
        TEMPLATE_ID,
        {
          name,
          interests: reason,
          message,
          email,
          phone,
        },
        PUBLIC_KEY
      )
      .then(() => {
        notifySuccess();
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setReason("Chiropractic Care");
        setValidated(false);
      })
      .catch(() => {
        notifyFailure();
      });
  }

  return (
    <>
      <Header />
      <ContactContainer>
        <ContactTitle>Connect with our team using the form below.</ContactTitle>
        <FormContainer>
          <InfoContainer>
            <InfoTitle>Contact Us!</InfoTitle>
            <InfoSubtitle>Hours</InfoSubtitle>
            <InfoContent>Monday: 9 AM - 12 PM | 2 PM - 6 PM</InfoContent>
            <InfoContent>Tuesday: 2 PM - 6 PM</InfoContent>
            <InfoContent>Wednesday: 9 AM - 12 PM | 2 PM - 6 PM</InfoContent>
            <InfoContent>Thursday: 9 AM - 12 PM | 2 PM - 6 PM</InfoContent>
            <InfoContent>Friday: 9:30 AM - 1 PM</InfoContent>
            <LineBreak />
            <InfoSubtitle>Location</InfoSubtitle>
            <InfoContent>28 Airport Road</InfoContent>
            <InfoContent>Lebanon, OR 97355</InfoContent>
            <LineBreak />
            <InfoSubtitle>Contact</InfoSubtitle>
            <InfoContent>frontdesk@wholebodyhealthlebanon.com</InfoContent>
            <InfoContent>Phone: (541) 405-4172</InfoContent>
            <InfoContent>Fax: (541) 405-4086</InfoContent>
          </InfoContainer>
          <StyledForm noValidate validated={validated} onSubmit={handleSubmit}>
            <StyledFormTitle>Contact Us!</StyledFormTitle>
            {/* Name Input Field */}
            <StyledFormGroup className="mb-3" controlId="form.Name">
              <StyledFormLabel>Full Name</StyledFormLabel>
              <Form.Control
                required
                type="text"
                name="name"
                placeholder="Name"
                maxLength={30}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setName(e.target.value)
                }
                value={name}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please provide a valid name.
              </Form.Control.Feedback>
            </StyledFormGroup>

            {/* Email Input Field */}
            <StyledFormGroup className="mb-3" controlId="form.Email">
              <StyledFormLabel>Email Address</StyledFormLabel>
              <Form.Control
                required
                type="email"
                name="email"
                placeholder="Email"
                maxLength={50}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
                value={email}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please provide a valid email.
              </Form.Control.Feedback>
            </StyledFormGroup>

            {/* Phone Input Field */}
            <StyledFormGroup className="mb-3" controlId="form.Phone">
              <StyledFormLabel>Phone Number</StyledFormLabel>
              <Form.Control
                required
                type="tel"
                name="phone"
                placeholder="Phone"
                pattern=".{14,}"
                onChange={handlePhoneInput}
                value={phone}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please provide a valid phone number.
              </Form.Control.Feedback>
            </StyledFormGroup>

            {/* Reason Dropdown */}
            <StyledFormGroup className="mb-3" controlId="form.Reason">
              <StyledFormLabel>Reason</StyledFormLabel>
              <Form.Select
                name="reason"
                required
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setReason(e.target.value)
                }
                value={reason}
              >
                <option value="Default" disabled>
                  Select a reason for contact
                </option>
                <option value="Chiropractic Care">Chiropractic Care</option>
                <option value="Pediatric Chiropractic">Pediatric Chiropractic</option>
                <option value="Performance Rehab">Performance Rehab</option>
                <option value="Post Concussion Therapy">Post Concussion Therapy</option>
                <option value="X-Ray Imaging">X-Ray Imaging</option>
                <option value="Physiotherapy">Physiotherapy</option>
                <option value="Manual Therapy">Manual Therapy</option>
                <option value="Other">Other</option>
              </Form.Select>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please provide a reason for contacting.
              </Form.Control.Feedback>
            </StyledFormGroup>

            {/* Message Input Field */}
            <StyledFormGroup className="mb-3" controlId="form.Message">
              <StyledFormLabel>Message</StyledFormLabel>
              <Form.Control
                required
                as="textarea"
                rows={5}
                name="message"
                placeholder="Tell me more..."
                maxLength={300}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                  setMessage(e.target.value)
                }
                value={message}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please provide a message.
              </Form.Control.Feedback>
            </StyledFormGroup>

            {/* Send Button */}
            <SendButton type="submit">Send Message</SendButton>

            {/* Toast Notifications */}
            <StyledToast
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </StyledForm>
        </FormContainer>
      </ContactContainer>
      <Footer />
    </>
  );
};

export default ContactPage;
